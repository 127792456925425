import axios from 'axios';
import authHeader from './auth-header';
import router from "@/router";
import store from "@/store";
const API_URL = process.env.VUE_APP_BASE_URL;

class DashboardServices {
    getData(data,path){
        return axios.get(API_URL + path,data,{headers: authHeader() });
    }
    postData(data,path){
        return axios.post(API_URL + path,data,{headers: authHeader() });
    }
    async checkMe(pathname){
        if(pathname==='/logout'){
            await store.commit('setLoggedIn',false);
            await store.commit('deletesession');
            await store.replaceState({})
        }else{
            let path = '/dashboard/refreshToken';
            let data = {};
            axios.post(API_URL + path,data,{headers: authHeader() })
                .then(async (res) => {
                    if (res.data.status === 401) {
                        router.push('/login')
                    } else if (res.data.status === 200) {
                        await store.commit('setperiode', res.data.tahunajaran);
                        await store.commit('savesession', res.data);
                    }
                })
        }
    }
    generateUrl(path){
        return API_URL+path;
    }
    refreshToken(){
        let path = '/dashboard/refreshToken';
        let data = {};
        return  axios.post(API_URL + path,data,{headers: authHeader() });
    }
}

export default new DashboardServices();